<template>
  <section class="">
    <Loader :loading="showLoader" />
    <div class="page-header">
      <h3 class="page-title">Edit
        Promotion Codes</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/promo-codes/promo-code-list" class="text-primary">
              Promotion Codes
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Edit
            Promotion Codes
          </li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
          <form @submit.prevent="editPromo">
              <div class="mb-1">
             
                <div class=" ">
                  <div class="pt-1">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Code</label>
                              <input
                                v-model="promo.code"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Amount</label>
                              <input
                                v-model="promo.amount"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">percentage</label>
                              <input
                                type="text"
                                v-model="promo.percentage"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Total</label>
                              <input
                                type="text"
                                v-model="promo.total"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                              <label for="name">Qty</label>
                              <input
                                type="number"
                                v-model="promo.qty"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Expiration Date </label>
                              <date-picker
                                v-model="promo.expiration_data"
                                format="YYYY-MM-DD"
                                valueType="format"
                              ></date-picker>
                            </div>
                          </div>

                          <div class="col-12">
                            <div class="form-group">
                              <label for="name"> Description</label>
                              <textarea
                                v-model="promo.description"
                                class="form-control"
                                rows="3"
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group mb-2 pb-1">
                              <label for="name" class="pr-2 pt-1">Active</label>
                              <label class="switch">
                                <input type="checkbox" v-model="promo.active" />
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 pt-1">
                        <button class="btn btn-primary btn-md btn-block">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
const orderApiUrl = process.env.VUE_APP_BASE_URL;
import DatePicker from "vue2-datepicker";
import axios from "axios";
import { otherApi } from "../../api";
import Loader from "../../components/Loader";
export default {
  name: "edit-promo-code",
  components: {
    Loader,
    DatePicker,
  },
  data() {
    return {
      promo: {
      },
      submitted: false,
      showLoader: false,
    };
  },
  mounted() {
    this.getPromoById();
  },
  methods: {
    async getPromoById() {
      this.showLoader = true;
      const { data } = await otherApi.listById(this.$route.params.id);
      this.promo = data.data.data;
    
      this.showLoader = false;
    },
    async editPromo() {
      this.submitted = true;
      if (this.promo.active === true) {
        this.promo.active = 1;
      } else {
        this.promo.active = 0;
      }
      this.showLoader = true;
        for (var key in this.promo) {
        if (this.promo.hasOwnProperty(key)) {
          var val = this.promo[key];
          if (val === null) {
            delete this.promo[key];
          }
        }
      }

      const data = await otherApi.updatePromotion(this.$route.params.id, {
        ...this.promo,
      });
      this.showLoader = false;
      this.$router.push("/promo-codes/promo-code-list");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>
<style>
.border {
  border: 1px solid #2125299e !important;
}
</style>